import React, { Component } from 'react';
import styled from 'styled-components'
import get from 'lodash/get'

class Banner extends Component {
    render(title, image) {
        const bannerTitle = get(this.props, 'title')
        const bannerImage = get(this.props, 'image')

        const HeaderImage = styled.section`
		  background-image: url(${bannerImage});
		  height: 400px;
		  width: 100%;
		  background-size: cover;
		  background-repeat: no-repeat;
		  background-position: 50% 60%;
		  margin-bottom: 40px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background-color: #424f52;
		`

		const Title = styled.h1`
		  margin: 80px 20px 0 20px;
    	  text-align: center;
		  color: #fff;
		  border: 4px solid;
		  padding: 10px 20px;
		  text-transform: uppercase;
		  font-weight: 100;

		  @media(min-width: 765px) {
		   	margin: 80px 0 0 0;
    	  	text-align: left;
		  }
		`

        return (
        	<HeaderImage>
      			<Title>{bannerTitle}</Title>
    		</HeaderImage>
        );	
    }
}
 

export default Banner

