import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from 'styled-components'
import BgImage from '../images/banner-images/services-banner.jpg';
import Banner from "../components/banner";

const ServicePage = () => (
  <Layout>
    <SEO title="Services" description="See the services ThowardMedia offer and what we can do for you." />

    <Banner title="Services" image={BgImage} />


    <MainWrapper>

    	<Service>
    		<h2><i class="fa fa-paint-brush"></i> Design</h2>
    		<p>Web design is an important part of any website, when a user visits your website you have seconds to engage the user.</p>
			<p>As a web developer, I not only understand that this is important, but a website can't just look 'pretty'. It also needs to function and be intuitive for the user.</p>
			<p>I design clean and modern websites that are responsive, meaning that they will work on a range of devices, giving users a great experience no matter what device they are browsing your website on.</p>
    	</Service>

    	<Service>
    		<h2><i class="fas fa-code"></i> Development</h2>
    		<p>Web development is more about getting the structure and functionality of your website working. For many people they just want a nice looking website to promote a service or their business, but it's important to understand that your website can do much more than just this!</p>
    		<p>When developing websites, I mainly use the Content Management System, Drupal and or GatsbyJS. Using a CMS allows you to easily edit your website content without having to contact your developer, but also gives you a good foundation for developing new functionality that you may like to add in the future.</p>
    	</Service> 

    	<Service>
    		<h2><i class="fas fa-wrench"></i> Maintenance</h2>
    		<p>Website maintenance sounds really boring and it kinda is! That said it is very important that your website is maintained, no matter what your website is built using.</p>
			<p>Having your website regularly updated keeps it secure and running. Websites that are not updated, are highly likely to be comprised and no one wants that!</p>
    	</Service> 

    	<Service>
    		<h2><i class="far fa-comments"></i> Technical support</h2>
    		<p>You might already have a site and would like some technical support to update the site or would like some advice on implementing a new feature.</p>
    	</Service> 

    	<CTA>Get in <a href="/contact">contact</a> today to see how ThowardMedia can best help you.</CTA>

    </MainWrapper>




  </Layout>
)

export default ServicePage


const MainWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-wrap: wrap;
`

const Service = styled.div`
	flex-basis: 100%;
	padding: 20px;
    h2 {
        color: red;
    }
	@media(min-width: 795px) {
		flex-basis: 50%;
	}
`

const CTA = styled.p`
	padding: 0 20px;
	text-align: center;
	width: 100%;
	font-size: 20px;
	a {
		color: red;
		text-decoration: none;
	}
`